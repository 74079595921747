import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./../ClientsReviewSlider/ClientReviewStyle.css";
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className="main_button_slider_div">
      <div className={className} style={{ ...style }} onClick={onClick} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className="main_button_slider_div">
      <div className={className} style={{ ...style }} onClick={onClick} />
    </div>
  );
}
function ClientReview() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <>
      <div className="main_div_client_review mb-5">
        <div className="below_main_div">
          <div className="main_heading_what_our_client">
            <h2 className="what_our_client_para">
              WHAT OUR <b>CLIENTS</b> SAY
            </h2>
          </div>
          <Slider {...settings}>
            <div className="backg_black">
              <div className="row m-0 p-0 ">
                <div className="space"></div>
              </div>
              <div className="row m-0 p-0">
                <div className="col-lg-1 col-md-1 col-1"></div>
                <div className="col-lg-1 col-md-1 col-1 stars_img"></div>
                <div className="col-lg-1 col-md-1 col-1 stars_img"></div>
                <div className="col-lg-1 col-md-1 col-1 stars_img"></div>
                <div className="col-lg-1 col-md-1 col-1 stars_img"></div>
                <div className="col-lg-7 col-md-7 col-7"></div>
              </div>
              <div className="row m-0 p-0">
                <div className="col-lg-1 col-md-1 col-0"></div>
                <div className="col-lg-10 col-md-10 col-12 paragraph">
                  “I was less than 2 months from losing my home because I was
                  behind on my payments… Spencer came in and payed a fair price
                  for my home and gave me extra time to move out after I
                  received the money. He went above and beyond to help me out”
                </div>
                <div className="col-lg-1 col-md-1 col-0"></div>
              </div>
              <div className="row m-0 p-0">
                <div className="col-lg-1 col-md-1 col-0"></div>
                <div className="col-lg-3 col-md-3 col-4 ">
                  <div className="ellipse_img"></div>
                </div>
                <div className="col-lg-5 col-md-5 col-8">
                  <div className="row m-0 p-0 ">
                    <p className="ellipse_text">
                      <strong className="ellipse_heading">Tracy M.</strong>
                      <br /> Architect
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-0"></div>
              </div>
              <div className="row m-0 p-0">
                <div className="space"></div>
              </div>
            </div>

            <div className="backg_black">
              <div className="row m-0 p-0 ">
                <div className="space"></div>
              </div>
              <div className="row m-0 p-0">
                <div className="col-lg-1 col-md-1 col-1"></div>
                <div className="col-lg-1 col-md-1 col-1 stars_img"></div>
                <div className="col-lg-1 col-md-1 col-1 stars_img"></div>
                <div className="col-lg-1 col-md-1 col-1 stars_img"></div>
                <div className="col-lg-1 col-md-1 col-1 stars_img"></div>
                <div className="col-lg-7 col-md-7 col-7"></div>
              </div>
              <div className="row m-0 p-0">
                <div className="col-lg-1 col-md-1 col-0"></div>
                <div className="col-lg-10 col-md-10 col-12 paragraph">
                  “I was less than 2 months from losing my home because I was
                  behind on my payments… Spencer came in and payed a fair price
                  for my home and gave me extra time to move out after I
                  received the money. He went above and beyond to help me out”
                </div>
                <div className="col-lg-1 col-md-1 col-0"></div>
              </div>
              <div className="row m-0 p-0">
                <div className="col-lg-1 col-md-1 col-0"></div>
                <div className="col-lg-3 col-md-3 col-4 ">
                  <div className="ellipse11_img"></div>
                </div>
                <div className="col-lg-5 col-md-5 col-8">
                  <div className="row m-0 p-0 ">
                    <p className="ellipse_text">
                      <strong className="ellipse_heading">
                        Michael Miller
                      </strong>
                      <br />
                      Software Engineer
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-0"></div>
              </div>
              <div className="row m-0 p-0">
                <div className="space"></div>
              </div>
            </div>

            <div className="backg_black">
              <div className="row m-0 p-0 ">
                <div className="space"></div>
              </div>
              <div className="row m-0 p-0">
                <div className="col-lg-1 col-md-1 col-1"></div>
                <div className="col-lg-1 col-md-1 col-1 stars_img"></div>
                <div className="col-lg-1 col-md-1 col-1 stars_img"></div>
                <div className="col-lg-1 col-md-1 col-1 stars_img"></div>
                <div className="col-lg-1 col-md-1 col-1 stars_img"></div>
                <div className="col-lg-7 col-md-7 col-7"></div>
              </div>
              <div className="row m-0 p-0">
                <div className="col-lg-1 col-md-1 col-0"></div>
                <div className="col-lg-10 col-md-10 col-12 paragraph">
                  “I was less than 2 months from losing my home because I was
                  behind on my payments… Spencer came in and payed a fair price
                  for my home and gave me extra time to move out after I
                  received the money. He went above and beyond to help me out”
                </div>
                <div className="col-lg-1 col-md-1 col-0"></div>
              </div>
              <div className="row m-0 p-0">
                <div className="col-lg-1 col-md-1 col-0"></div>
                <div className="col-lg-3 col-md-3 col-4 ">
                  <div className="ellipse13_img"></div>
                </div>
                <div className="col-lg-5 col-md-5 col-8">
                  <div className="row m-0 p-0 ">
                    <p className="ellipse_text">
                      <strong className="ellipse_heading">David Snow</strong>
                      <br /> Software Engineer
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-0"></div>
              </div>
              <div className="row m-0 p-0">
                <div className="space"></div>
              </div>
            </div>

            <div className="backg_black">
              <div className="row m-0 p-0 ">
                <div className="space"></div>
              </div>
              <div className="row m-0 p-0">
                <div className="col-lg-1 col-md-1 col-1"></div>
                <div className="col-lg-1 col-md-1 col-1 stars_img"></div>
                <div className="col-lg-1 col-md-1 col-1 stars_img"></div>
                <div className="col-lg-1 col-md-1 col-1 stars_img"></div>
                <div className="col-lg-1 col-md-1 col-1 stars_img"></div>
                <div className="col-lg-7 col-md-7 col-7"></div>
              </div>
              <div className="row m-0 p-0">
                <div className="col-lg-1 col-md-1 col-0"></div>
                <div className="col-lg-10 col-md-10 col-12 paragraph">
                  “I was less than 2 months from losing my home because I was
                  behind on my payments… Spencer came in and payed a fair price
                  for my home and gave me extra time to move out after I
                  received the money. He went above and beyond to help me out”
                </div>
                <div className="col-lg-1 col-md-1 col-0"></div>
              </div>
              <div className="row m-0 p-0">
                <div className="col-lg-1 col-md-1 col-0"></div>
                <div className="col-lg-3 col-md-3 col-4 ">
                  <div className="ellipseNew_img"></div>
                </div>
                <div className="col-lg-5 col-md-5 col-8">
                  <div className="row m-0 p-0 ">
                    <p className="ellipse_text">
                      <strong className="ellipse_heading">Emily</strong>
                      <br /> Architect
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-0"></div>
              </div>
              <div className="row m-0 p-0">
                <div className="space"></div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
}

export default ClientReview;
