import React from "react";
import "./../Steps/StepsStyle.css";
function Steps() {
  return (
    <div className="container">
      <div className="rectangle_91">
        <div className="row m-0 p-0 ">
          <div className="fromtop_space"></div>
          <div className="col-lg-1 col-md-1 col-1"></div>
          <div className="col-lg-2 col-md-2 col-2">
            <div className="row m-0 p-0 ">
              <div className="col-lg-2 col-md-2 col-2"></div>
              <div className="col-lg-4 col-md-4 col-4 purchase_img"></div>
            </div>
            <div className="row m-10 p-0 ">
              <p className="Purchase">Purchase In Any Condition</p>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-2">
            <div className="row m-0 p-0 ">
              <div className="col-lg-2 col-md-2 col-2"></div>
              <div className="col-lg-4 col-md-4 col-4 cash_img"></div>
            </div>
            <div className="row m-10 p-0 ">
              <p className="Purchase">Highest Cash Offer</p>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-2">
            <div className="row m-0 p-0 ">
              <div className="col-lg-2 col-md-2 col-2"></div>
              <div className="col-lg-4 col-md-4 col-4 plumber_img"></div>
            </div>
            <div className="row m-10 p-0 ">
              <p className="Purchase">No Repair Needed</p>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-2">
            <div className="row m-0 p-0 ">
              <div className="col-lg-2 col-md-2 col-2"></div>
              <div className="col-lg-4 col-md-4 col-4 fees_img"></div>
            </div>
            <div className="row m-10 p-0 ">
              <p className="Purchase">No Fees or Commision</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-2">
            <div className="row m-0 p-0 ">
              <div className="col-lg-2 col-md-2 col-2"></div>
              <div className="col-lg-4 col-md-4 col-4 online_img"></div>
            </div>
            <div className="row m-10 p-0 ">
              <p className="Purchase">Virtual Consultation Available</p>
            </div>
          </div>
          {/*<div className="col-lg-1 col-md-1 col-1"></div>*/}
        </div>
      </div>
      <div className="steps_main_class">
        <div className="row m-0 p-0 ">
          <div className="top_space"></div>
          <div className="col-lg-3 col-md-3 col-3"></div>
          <div className="col-lg-6 col-md-6 col-6">
            {" "}
            <p className="heading_text">
              Three <span className="easy_text">Easy</span> Steps
            </p>
          </div>
          <div className="col-lg-3 col-md-3 col-3"></div>
        </div>

        <div className="row m-0 p-0 ">
          <div className="second_space"></div>

          <div className="col-lg-3 col-md-3 col-12 ">
            <div className="row m-0 p-0 ">
              <div className="consul_img"></div>
            </div>
            <div className="row m-0 p-0 ">
              <p className="consul_heading">
                Schedule a Free Home Consultation
              </p>
            </div>
            <div className="row m-0 p-0 ">
              <p className="consul_para">
                We will learn about your needs and assess your home by walking
                through the property with you, explaining the process as we go
              </p>
            </div>
            <div className="row m-0 p-0 ">
              <div className="Cheque_img"></div>
            </div>
            <div className="row m-0 p-0 ">
              <p className="consul_heading">Close At Your Convenience</p>
            </div>
            <div className="row m-0 p-0 ">
              <p className="consul_para">
                Your property will be purchased with cash, so you can close on
                your schedule.{" "}
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-3 col-12">
            <div className="row m-0 p-0 ">
              <div className="Check_Book_img"></div>
            </div>
            <div className="row m-0 p-0 ">
              <p className="consul_heading">Get A Cash Offer Today</p>
            </div>
            <div className="row m-0 p-0 ">
              <p className="consul_para">
                We will learn about your needs and assess your home by walking
                through the property with you, explaining the process as we go.
              </p>
            </div>
          </div>

          <div className="col-lg-2 col-md-2 col-4 ">
            <div className="img_one"></div>
          </div>
          <div className="col-lg-2 col-md-2 col-4 ">
            <div className="img_two"></div>
          </div>
          <div className="col-lg-2 col-md-2 col-4 ">
            <div className="img_three"></div>
          </div>
          <div className="col-lg-1 col-md-1 col-1"></div>
        </div>
      </div>
      <div className="row m-0 p-0">
        {" "}
        <div className="Endspace_end"></div>
      </div>
    </div>
  );
}

export default Steps;
