import React, { useState } from "react";
import logohome from "./../../Assets/logohome.png";
import phone from "./../../Assets/phone.png";
import "./../NavbarHome/navbarStyle.css";

function NavbarHomeDirect() {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="container">
      <nav className="navbar navbar-expand-lg navbar-light ">
        <div className="container-fluid">
          <a className="navbar-brand " href="#">
            <img src={logohome} alt="Logo" className="logo-home" />
          </a>
          <div className="">
            <p className="HOME-OFFER">HOME OFFER</p>
            <p className="DIRECT">DIRECT</p>
          </div>

          <button
            className="navbar-toggler"
            type="button"
            onClick={handleToggle}
            aria-controls="navbarSupportedContent"
            aria-expanded={!isCollapsed}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className={`collapse navbar-collapse${isCollapsed ? "" : " show"}`}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 center_navbar_text ">
              <li className="nav-item">
                <a
                  className="nav-link  How-It-Works"
                  aria-current="page"
                  href="#howitwork"
                >
                  How It Works
                </a>
              </li>
              <li className="nav-item why-us">
                <a className="nav-link" href="#whyus">
                  Why Us
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link reviews" href="#reviews">
                  Reviews
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link faqs" href="#faqs">
                  FAQs
                </a>
              </li>
            </ul>
            <form className="d-flex">
              <div className="row button_end_of_navbar">
                <div className="col-lg-2 col-md-2 col-2">
                  <img className="phone-icon" src={phone} alt="Phone" />
                </div>
                <div className="col-lg-10 col-md-10 col-10">
                  <p className="contact_us_for_free">Call US For Free</p>
                  <p className="phone_number_in_navbar">866-928-4635</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default NavbarHomeDirect;
