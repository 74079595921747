import React from "react";
import "./../ScheduleAFreeConsultation/ScheduleFreeStyle.css";
function ScheduleFree() {
  return (
    <div className="main_background_img">
      <div className="row m-0 p-0 ">
        <div className="col-lg-2 col-md-2 col-1 "></div>
        <div className="col-lg-6 col-md-6 col-10">
          <p className="Schedule_main_heading">Schedule A Free </p>
          <p className="today_text">
            <span className="consultation_text">consultation </span>Today
          </p>
        </div>
        <div className="col-lg-4 col-md-4 col-1 "></div>
      </div>

      <div className="row m-0 p-0">
        <div className="col-lg-2 col-md-2 col-1"></div>
        <div className="col-lg-6 col-md-6 col-10">
          <p className="hours">We can close in less than 72 hours if needed</p>
        </div>
        <div className="col-lg-4 col-md-4 col-1 "></div>
      </div>

      <div className="row m-0 p-0">
        <div className="col-lg-2 col-md-2 col-1"></div>
        <div className="col-lg-6 col-md-6 col-10">
        <a href="">
          <button className="schedule_button" type="submit">
            Get My Offer Now!
          </button>
          </a>
        </div>
        <div className="col-lg-4 col-md-4 col-1"></div>
      </div>

      <div className="row m-0 p-0">
        <div className="col-lg-2 col-md-2 col-1"></div>
        <div className="col-lg-6 col-md-6 col-10">
          <p className="schedule_call_us">Or Call Us For FREE <span className="number">866-928-4635</span></p>
        </div>
        <div className="col-lg-4 col-md-4 col-1"></div>
      </div>

    </div>
  );
}

export default ScheduleFree;
