import "./App.css";
import Homepage from "./Components/Homepage/Homepage";

function App() {
  return (
    <>
      <Homepage />
    </>
  );
}

export default App;
