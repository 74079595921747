import React from 'react'
import "./../SellingWithUs/SellingWithUsStyle.css"
function SellingWithUsRealtor() {
  return (
    <div className='Selling_main_class'>

      <div className='row m-0 p-0'>
      <div className="col-lg-4 col-md-0 col-0"></div>
      <div className="col-lg-4 col-md-12 col-12">
      <p className='SellingWith'>Selling With <span className='us'>Us</span> 
      <p className='vs'>VS.</p>
      Listing With <span className='us'>A Realtor</span></p>
      </div>
      <div className="col-lg-4 col-md-0 col-0"></div>
      </div>

      <div className='row m-0 p-0 '><div className='space_margin'></div>
      <div className="col-lg-2 col-md-2 col-1"></div>

      <div className="col-lg-2 col-md-2 col-5">
      <div className='row m-0 p-0 Commision'> <p className='comm_text'>Commision / Fee</p></div>
      <div className='row m-0 p-0 white_backg'>
      <div className="col-lg-3 col-md-3 col-3">
      <div className='row m-0 p-0 '><div className='true_img'></div></div>
      <div className='row m-0 p-0 '><div className='false_img'></div></div>
      </div>
      <div className="col-lg-9 col-md-9 col-9"> 
      <div className='row m-0 p-0 '><p className='true_para'>NONE</p></div>
      <div className='row m-0 p-0 '><p className='false_para'>6% on average is paid by you</p></div>
      </div>
      </div>
      </div>

      <div className="col-lg-2 col-md-2 col-5 row_margin">
      <div className='row m-0 p-0 Commision'> <p className='pay_text'>Who Pays Closing <br/>Costs?</p></div>
      <div className='row m-0 p-0 white_backg'>
      <div className="col-lg-3 col-md-3 col-3">
      <div className='row m-0 p-0 '><div className='true_img'></div></div>
      <div className='row m-0 p-0 '><div className='false_img'></div></div>
      </div>
      <div className="col-lg-9 col-md-9 col-9"> 
      <div className='row m-0 p-0 '><p className='true_para'>NO Closing Costs</p></div>
      <div className='row m-0 p-0 '><p className='false_para'>2% on average is paid by you</p></div>
      </div>
      </div>
      </div>
      
  {/*<div className='row m-0 p-0'><div className='row_space'></div></div>*/}
      <div className="col-lg-2 col-md-2 col-5 row_margin2">
      <div className='row m-0 p-0 Commision'> <p className='pay_text'>Inspections & 
      <br/>Financing Contingency*</p></div>
      <div className='row m-0 p-0 white_backg'>
      <div className="col-lg-3 col-md-3 col-3">
      <div className='row m-0 p-0 '><div className='true_img'></div></div>
      <div className='row m-0 p-0 '><div className='false_img'></div></div>
      </div>
      <div className="col-lg-9 col-md-9 col-9"> 
      <div className='row m-0 p-0 '><p className='true_para'>NONE</p></div>
      <div className='row m-0 p-0 '><p className='false_para'>Yes, up to 15% of sales fall
      through</p></div>
      </div>
      </div>
      </div>

      <div className="col-lg-2 col-md-2 col-5 row_margin3">
      <div className='row m-0 p-0 Commision'> <p className='comm_text'>Appraisal Needed</p></div>
      <div className='row m-0 p-0 white_backg'>
      <div className="col-lg-3 col-md-3 col-3">
      <div className='row m-0 p-0 '><div className='true_img'></div></div>
      <div className='row m-0 p-0 '><div className='false_img'></div></div>
      </div>
      <div className="col-lg-9 col-md-9 col-9"> 
      <div className='row m-0 p-0 '><p className='true_para'>None-We make cash offers</p></div>
      <div className='row m-0 p-0 '><p className='false_para'>Yes, sale is often subject to appraisal</p></div>
      </div>
      </div>
      </div>
      
      <div className="col-lg-2 col-md-2 col-1"></div>
      </div>      
{/*end */}

      <div className='row m-0 p-0 '><div className='space_margin'></div>
      <div className="col-lg-2 col-md-2 col-1"></div>

      <div className="col-lg-2 col-md-2 col-5">
      <div className='row m-0 p-0 Commision'> <p className='pay_text'>Average Days
      <br/>Until Sold</p></div>
      <div className='row m-0 p-0 white_backg'>
      <div className="col-lg-3 col-md-3 col-3">
      <div className='row m-0 p-0 '><div className='true_img'></div></div>
      <div className='row m-0 p-0 '><div className='false_img'></div></div>
      </div>
      <div className="col-lg-9 col-md-9 col-9"> 
      <div className='row m-0 p-0 '><p className='true_para'>IMMEDIATE Cash Offer</p></div>
      <div className='row m-0 p-0 '><p className='false_para'>+/- 91 Days</p></div>
      </div>
      </div>
      </div>

      <div className="col-lg-2 col-md-2 col-5 row_margin">
      <div className='row m-0 p-0 Commision'> <p className='comm_text'>Number of Showings</p></div>
      <div className='row m-0 p-0 white_backg'>
      <div className="col-lg-3 col-md-3 col-3">
      <div className='row m-0 p-0 '><div className='true_img'></div></div>
      <div className='row m-0 p-0 '><div className='false_img'></div></div>
      </div>
      <div className="col-lg-9 col-md-9 col-9"> 
      <div className='row m-0 p-0 '><p className='true_para'>1 (Just Us)</p></div>
      <div className='row m-0 p-0 '><p className='false_para'>Multiple Showings</p></div>
      </div>
      </div>
      </div>
      
      <div className="col-lg-2 col-md-2 col-5 row_margin2">
      <div className='row m-0 p-0 Commision'> <p className='comm_text'>Closing Date</p></div>
      <div className='row m-0 p-0 white_backg'>
      <div className="col-lg-3 col-md-3 col-3">
      <div className='row m-0 p-0 '><div className='true_img'></div></div>
      <div className='row m-0 p-0 '><div className='false_img'></div></div>
      </div>
      <div className="col-lg-9 col-md-9 col-9"> 
      <div className='row m-0 p-0 '><p className='true_para'>Close on Your Time Frame</p></div>
      <div className='row m-0 p-0 '><p className='false_para'>30-60 days after buyers offer</p></div>
      </div>
      </div>
      </div>

      <div className="col-lg-2 col-md-2 col-5 row_margin3">
      <div className='row m-0 p-0 Commision'> <p className='comm_text'>Who Pay For Repairs?</p></div>
      <div className='row m-0 p-0 white_backg'>
      <div className="col-lg-3 col-md-3 col-3">
      <div className='row m-0 p-0 '><div className='true_img'></div></div>
      <div className='row m-0 p-0 '><div className='false_img'></div></div>
      </div>
      <div className="col-lg-9 col-md-9 col-9"> 
      <div className='row m-0 p-0 '><p className='true_para'>NO REPAIRS NEEDED</p></div>
      <div className='row m-0 p-0 '><p className='false_para'>Paying for Repairs or Upkeep</p></div>
      </div>
      </div>
      </div>
      
      <div className="col-lg-2 col-md-2 col-1"></div>
      </div>      


    </div>
  )
}

export default SellingWithUsRealtor
