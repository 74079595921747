import React from "react";
import "./../QuestionAns/QuestionAnsStyle.css";
function QuestionAns() {
  return (
    <div className="Ques_main_class">
      <p className="Ques_heading">
        <span className="Ques_highlight">Questions?</span> We Have Answers.
      </p>

      <div className="container">
        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                How does the process work to sell my house?
              </button>
            </h2>

            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                We buy houses in any condition, in any area, in any price range,
                in any situation! We will buy your house as-is, you don’t need
                to do ANY repairs!
              </div>
            </div>
          </div>
          <hr className="hr_line" />

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                What sort of houses do you buy?
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                We buy houses in any condition, in any area, in any price range,
                in any situation! We will buy your house as-is, you don’t need
                to do ANY repairs!
              </div>
            </div>
          </div>
          <hr className="hr_line" />

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                Are you REALTORS™?
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                We buy houses in any condition, in any area, in any price range,
                in any situation! We will buy your house as-is, you don’t need
                to do ANY repairs!
              </div>
            </div>
          </div>
          <hr className="hr_line" />

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFour">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                What do you mean “any condition, area, price range, or
                situation”?
              </button>
            </h2>
            <div
              id="collapseFour"
              className="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                YES! Home Offer Direct is a professional real estate solutions
                company with years of experience in solving these types of
                difficult situations. Please contact us for a confidential
                consultation.
              </div>
            </div>
          </div>
          <hr className="hr_line" />

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFive">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                What if I am behind on my payments, in foreclosure or
                bankruptcy?
                <br />
                Are you still able help?
              </button>
            </h2>
            <div
              id="collapseFive"
              className="accordion-collapse collapse"
              aria-labelledby="headingFive"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                YES! Home Offer Direct is a professional real estate solutions
                company with years of experience in solving these types of
                difficult situations. Please contact us for a confidential
                consultation.
              </div>
            </div>
          </div>
          <hr className="hr_line" />

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingSix">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSix"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                What will your service cost me?
              </button>
            </h2>
            <div
              id="collapseSix"
              className="accordion-collapse collapse"
              aria-labelledby="headingSix"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                YES! Home Offer Direct is a professional real estate solutions
                company with years of experience in solving these types of
                difficult situations. Please contact us for a confidential
                consultation.
              </div>
            </div>
          </div>
          <hr className="hr_line" />

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingSeven">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSeven"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                Is my information kept confidential?
              </button>
            </h2>
            <div
              id="collapseSeven"
              className="accordion-collapse collapse"
              aria-labelledby="headingSeven"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                YES! Home Offer Direct is a professional real estate solutions
                company with years of experience in solving these types of
                difficult situations. Please contact us for a confidential
                consultation.
              </div>
            </div>
          </div>
          <hr className="hr_line" />
        </div>
      </div>

      <div className="row m-0 p-0 ">
        <div className="ending_space"></div>
      </div>
    </div>
  );
}

export default QuestionAns;
