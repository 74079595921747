import React from "react";
import "./../BackgroundHome/HomeBuyerstyle.css";
function HomeBuyer() {
  return (
    <div className="main_div_home_buyer ">
      <div className="row m-0 p-0">
        {/* take 1 space out of 12 */}
        <div className="col-lg-1 col-md-1 col-1 "></div>
        <div className="col-lg-10 col-md-10 col-10">
          <p className="homer_buyer_main_heading">
            AMERICA’S MOST TRUSTED HOME BUYER
          </p>
        </div>
        <div className="col-lg-1 col-md-1 col-1  "></div>
      </div>

      <div className="row m-0 p-0">
        <div className="col-lg-4 col-md-4 col-4  "></div>
        <div className="col-lg-4 col-md-4 col-4">
          <p className="home_buyer_description">
            Schedule your free consultation today to find out how we can help
            you
          </p>
        </div>
        <div className="col-lg-4 col-md-4 col-4  "></div>
      </div>

      <div className="row m-0 p-0">
        <div className="col-lg-4 col-md-4 col-4  "></div>
        <div className="col-lg-4 col-md-4 col-4">
          <form>
            <div className="home_main_buyer_form">
              <p className="form_heading">
                {" "}
                Get Your Cash Offer In 30 Minutes Or Less!
              </p>
              <div className="form_name row">
                <div className="col-lg-5 col-md-5 col-5">
                  <input
                    type="text"
                    className="input_first_name"
                    placeholder="First Name*"
                  />
                </div>
                <div className="col-lg-5 col-md-5 col-5">
                  <input
                    type="text"
                    className="input_first_name"
                    placeholder="Last Name*"
                  />
                </div>
              </div>

              <div className="form_name row">
                <div className="col-lg-5 col-md-5 col-5">
                  <input
                    type="email"
                    className="input_email"
                    placeholder="Email*"
                  />
                </div>
                <div className="col-lg-5 col-md-5 col-5">
                  <input
                    type="text"
                    className="input_email"
                    placeholder="Phone Number*"
                  />
                </div>
              </div>

              <div className="">
                <input
                  type="text"
                  className="input_address"
                  placeholder="Address*"
                />
              </div>
              <div className="">
                <button className="button_form" type="submit">
                  Get My Offer Now!
                </button>{" "}
              </div>
            </div>
          </form>
        </div>
        <div className="col-lg-4 col-md-4 col-4  "></div>
      </div>
    </div>
  );
}

export default HomeBuyer;
