import React from "react";
import NavbarHomeDirect from "../NavbarHome/navbarHomeDirect";
import HomeBuyer from "../BackgroundHome/HomeBuyer";
import Steps from "../Steps/Steps";
import SellingWithUsRealtor from "../SellingWithUs/SellingWithUsRealtor";
import ScheduleFree from "../ScheduleAFreeConsultation/ScheduleFree";
import SellingYourHome from "../SellingYourHome/SellingYourHome";
import ClientReview from "../ClientsReviewSlider/ClientReview";
import QuestionAns from "../QuestionAns/QuestionAns";
import AtYourConvenience from "../AtYourConvenience/AtYourConvenience";

function Homepage() {
  return (
    <div>
      <NavbarHomeDirect />
      <HomeBuyer />
      <div id="howitwork">
        <Steps />
      </div>
      <div id="whyus">
        <SellingWithUsRealtor />
      </div>
      <ScheduleFree />
      <SellingYourHome />
      <div id="reviews">
        <ClientReview />
      </div>
      <div id="faqs">
        <QuestionAns />
      </div>
      <AtYourConvenience />
    </div>
  );
}

export default Homepage;
