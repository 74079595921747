import React from "react";
import "./../AtYourConvenience/AtYourConvenienceStyle.css";
function AtYourConvenience() {
  return (
    <div className="main_background_image">
      <div className="row m-0 p-0 ">
        <div className="col-lg-2 col-md-2 col-1 "></div>
        <div className="col-lg-8 col-md-8 col-10">
          <p className="Convenience_main_heading">
            Close At Your <span className="Convenience_text">Convenience </span>{" "}
          </p>
        </div>
        <div className="col-lg-2 col-md-2 col-1  "></div>
      </div>

      <div className="row m-0 p-0">
        <div className="col-lg-2 col-md-2 col-0 "></div>
        <div className="col-lg-8 col-md-8 col-12">
          <p className="Convenience_hours">
            Selling your home has never been so easy!
          </p>
        </div>
        <div className="col-lg-2 col-md-2 col-0"></div>
      </div>

      <div className="row m-0 p-0">
        <div className="col-lg-2 col-md-2 col-2 "></div>
        <div className="col-lg-8 col-md-8 col-8 btn_margin">
          <a href="">
            <button className="Convenience_button" type="submit">
              Get My Offer Now!
            </button>
          </a>
        </div>
        <div className="col-lg-2 col-md-2 col-2  "></div>
      </div>

      <div className="row m-0 p-0">
        <div className="col-lg-2 col-md-2 col-0 "></div>
        <div className="col-lg-8 col-md-8 col-12">
          <p className="Convenience_call_us">
            Or Call Us For FREE{" "}
            <span className="Convenience_number">866-928-4635</span>
          </p>
        </div>
        <div className="col-lg-2 col-md-2 col-0 "></div>
      </div>
    </div>
  );
}

export default AtYourConvenience;
