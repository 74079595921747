import React from "react";
import "./../SellingYourHome/SellingYourHomeStyle.css";
function SellingYourHome() {
  return (
    <div className="main_class">
      <div className="row m-0 p-0">
        <div className="col-lg-2 col-md-2 col-2"></div>
        <div className="col-lg-3 col-md-12 col-12">
          <div className="selling">
            <p className="First_text">Selling Your Home Should Be Simple</p>
          </div>
        </div>

        <div className="col-lg-4 col-md-4 col-12 center_text">
          <div className="row m-0 p-0">
            <div className="col-lg-1 col-md-1 col-1"></div>
            <div className="col-lg-2 col-md-2 col-2">
              <div className="first_img"></div>
            </div>
            <div className="col-lg-8 col-md-8 col-8"><p className="client_text"> Client Satisfaction Guaranteed</p>
            <div className=""> <p className="client_para">We pride ourselves on a world class experience.
            Inquire today to find out about our client
            satisfaction guarantee.</p></div>
            </div>
          </div>
          
          <div className="row m-0 p-0">
          <div className="col-lg-1 col-md-1 col-1"></div>
          <div className="col-lg-2 col-md2 col-2">
            <div className="second_img"></div>
          </div>
          <div className="col-lg-8 col-md-8 col-8"><p className="client_text"> Be a part of Our Community</p>
          <div className=""> <p className="client_para">Join the thousands of sellers we've helped get
          the cash they need.</p></div>
          </div>
        </div>

        <div className="row m-0 p-0">
            <div className="col-lg-1 col-md-1 col-1"></div>
            <div className="col-lg-2 col-md-2 col-2 ">
              <div className="third_img"></div>
            </div>
            <div className="col-lg-8 col-md-8 col-8"><p className="client_text"> Our Offer Won't Change</p>
            <div className=""> <p className="client_para">Unlike websites that give you an online "offer" and
            then change it later - we'll provide you with
            a real cash offer that doesn't change at closing.</p></div>
            </div>
          </div>

        </div>
        

        <div className="col-lg-3 col-md-3 col-12 selling_img">
        
        
        </div>



      </div>
      <div className="row m-0 p-0 "><div className="end_space"></div></div>

    </div>
  );
}

export default SellingYourHome;
